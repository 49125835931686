.circle-check {
  &__wrapper {
    position: relative;
    height: 32px;
    min-width: 32px;

    &:before {
      z-index: 0;
      content: '';
      position: absolute;
      width: 36px;
      left: -2px;
      top: -2px;
      height: 36px;
      background-color: $white;
      border: 1px solid $primary;
      border-radius: 50%;
    }

    &.is-active {
      .circle-check {
        background-color: $primary;

        .uil-check {
          opacity: 1;
        }
      }
    }
  }

  position: absolute;
  background-color: white;
  color: $white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  top: 16px;
  left: 16px;
  transform: translate(-50%, -50%);
  transition: background-color 200ms linear;

  .uil-check {
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.3rem;
    transition: opacity 200ms linear;
    opacity: 0;
  }
}

;@import "sass-embedded-legacy-load-done:137";