.scrollable-section-tabs {
  transition: transform 200ms linear;
  display: flex !important;
  justify-content: space-between;

  &__single {
    color: $darker-gray;
    cursor: pointer;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    all: unset;

    @include screen-xl-min {
      font-size: $font-lg;
    }
  }

  &__wrapper {
    padding-bottom: 3px;
    border-bottom: 2px solid #d1d1d1;
    padding-left: 0;
    padding-right: 0;
    max-width: 700px;
  }

  .is-active {
    font-weight: bold;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: -1rem;
      bottom: -5px;
      height: 2px;
      width: calc(100% + 25px);
      background-color: $gold;
      user-select: none;
    }
  }

  .is-crossed {
    pointer-events: none;
    text-decoration: line-through;
    text-decoration-thickness: 2px;
    text-decoration-color: $gold;
  }
}

.scrollable-section {
  overflow-x: hidden;

  &__title {
    color: $dark;

    @include screen-xl-min {
      color: $primary;
    }
  }

  &__card {
    &__image {
      border: 1px solid #e3e3e3;
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-top-right-radius: 0.25rem;
      border-top-left-radius: 0.25rem;
      max-height: 315px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:103";