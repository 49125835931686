@keyframes hideText {
  from {
    line-clamp: initial;
    -webkit-line-clamp: initial;
  }

  to {
    line-clamp: 2;
    -webkit-line-clamp: 2;
  }
}

@keyframes showText {
  from {
    line-clamp: 2;
    -webkit-line-clamp: 2;
  }

  to {
    line-clamp: initial;
    -webkit-line-clamp: initial;
  }
}

.expandable-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: max-height 400ms linear;
  max-height: 26px;
  animation: hideText 100ms 500ms linear forwards;

  &.is-expanded {
    max-height: 150px !important;
    animation: showText 100ms linear forwards;
  }

  &__arrow {
    font-size: $font-lg;
    transform: rotate(-90deg);
  }

  &__arrow {
    transition: transform 200ms linear;
    transform: rotate(0);
  }

  &__action {
    &.is-expanded {
      .expandable-text__arrow {
        transform: rotate(180deg);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:128";