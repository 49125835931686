.icon-arrow_top {
  height: 40px;
  width: 40px;
}

.icon-facebook,
.icon-instagram,
.icon-youtube {
  height: 27px;
  width: 27px;
}

.icon-check_selected {
  height: 25px;
  width: 25px;
}

.icon-shield_watermark {
  height: 199px;
  width: 174px;
}

.icon-shield {
  height: 29px;
  width: 26px;
}

.icon-booking,
.icon-tripadvisor,
.icon-google {
  height: 17px;
  width: 17px;
}

.icon-mastercard {
  width: 42px;
  height: 26px;
}

.icon-mbankraty {
  height: 27px;
  width: 35px;
}
.icon-paypo {
  height: 30px;
  width: 80px;
}
.icon-przelewy24 {
  height: 30px;
  width: 70px;
}

.icon-santanderraty {
  height: 30px;
  width: 85px;
}

.icon-visa {
  height: 23px;
  width: 68px;
}

.icon-blik {
  height: 30px;
  width: 46px;
}

.icon-googlepay {
  height: 30px;
  width: 47px;
}

.icon-holiday {
  height: 40px;
  width: 140px;
}

.icon-aliorraty {
  height: 27px;
  width: 47px;
}

.icon-applepay {
  height: 29px;
  width: 45px;
}

.icon-bnpraty {
  height: 25px;
  width: 65px;
}

.icon-lang_en,
.icon-lang_pl,
.icon-lang_de {
  height: 18px;
  width: 18px;
}

.icon-grass {
  height: 11px;
  width: 29px;
}

.icon-percent {
  height: 30px;
  width: 50px;
}

.icon-subscription-with-benefits-logo {
  width: 110px;
  min-width: 110px;
  height: 95px;
}

.icon-tag {
  width: 100px;
  height: 100px;
}

;@import "sass-embedded-legacy-load-done:110";