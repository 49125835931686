body.modal-open {
  position: inherit;
  max-height: 100vh;
}

.custom-modal {
  .modal-xl {
    @include screen-xl-min {
      max-width: 980px;
    }
  }

  &.modal-semi-lg {
    .modal-dialog {
      @include screen-xl-min {
        max-width: 580px;
      }
    }
  }

  .modal-title {
    position: relative;
    min-height: 55px;

    @include screen-xl-min {
      &:after {
        pointer-events: none;
        content: '';
        position: absolute;
        height: calc(100% + 1px);
        width: 55px;
        right: 0;
        top: 0;
        border-bottom: 1px solid #e6e7e8;
        border-left: 1px solid #e6e7e8;
      }
    }
  }

  .modal-dialog {
    @include screen-lg-max {
      bottom: 0;
      height: auto;
      position: absolute;
      max-height: 100vh;
      max-height: -webkit-fill-available;
    }
  }

  &__title {
    min-height: 40px;
    line-height: initial;
    font-size: 20px;
    color: $primary;
  }

  &__close-button {
    display: inline-block;
    font-size: 30px;
    height: 30px;
    line-height: 1;
    all: unset;
    z-index: 2;

    @include screen-xl-min {
      font-size: 20px;
      height: 20px;
    }
  }

  & .modal-fullscreen-xl-down {
    @include screen-lg-max {
      padding-top: 15px;
    }
  }

  & .modal-content {
    @include screen-lg-max {
      border-top-right-radius: 23px;
      border-top-left-radius: 23px;
    }
  }

  & .modal-body {
    padding: 0;
  }

  & .modal-content {
    transition: opacity 300ms ease-out !important;
  }

  & .modal-header {
    padding-left: 0;
    padding-right: 0;
  }

  &--top-offset-0 .modal-fullscreen-xl-down {
    padding-top: 0;
  }

  &--big-top-offset {
    padding-top: 25vh;
  }

  &--rounded-0 .modal-content {
    border-radius: 0;
  }

  &--nested {
    @include screen-xl-min {
      padding-right: 15px;
    }
  }

  &.is-notification-modal {
    z-index: 1090;
    background-color: rgba(0, 0, 0, 0.3) !important;

    .modal-content {
      width: 95%;
      margin: 0 auto;
      max-width: 600px;
      border-radius: 0.25rem;
    }

    .custom-modal__close-button:after {
      border-top-right-radius: 3px !important;
    }
  }

  &.is-reservation-expiration-modal.custom-modal-with-image .modal-content {
    @include screen-lg-max {
      border-top-right-radius: 19px !important;
    }
  }

  &.is-reservation-expiration-modal {
    .custom-modal__close-button {
      display: none;
    }
  }

  &.is-login-modal {
    .modal-content {
      max-width: 900px;
      margin: 0 auto;
      border-top-right-radius: 1rem;
    }

    .custom-modal-with-image__image {
      width: 45%;
      border-top-right-radius: calc(0.5rem + 6px);
    }

    .custom-modal-with-image__content {
      width: 55%;
    }

    .custom-modal__close-button {
      z-index: 2;

      &:after {
        border-top-right-radius: calc(0.5rem + 2px);
      }
    }

    @include screen-sm-max {
      .custom-modal-with-image__image {
        display: none;
      }

      .custom-modal-with-image__content {
        width: 100%;
        max-width: 100%;
      }

      .custom-modal__close-button {
        &:after {
          background-color: white;
        }

        &:before {
          color: $darker-gray;
        }
      }
    }
  }

  &.is-medium-large-desktop {
    .modal-content {
      @include screen-xl-min {
        max-width: 600px;
        margin: 0 auto;
      }
    }
  }

  &.base-improvement-modal {
    .scrollable-section__card__image {
      border-radius: 0;
      min-height: 300px;
      height: 300px;
      max-height: 300px;
    }
  }

  &.borderless-header {
    .modal-header {
      border: none;
    }
  }

  &.black-week-modal {
    .modal-header,
    .modal-content {
      border: none;
    }

    .modal-dialog {
      max-width: 600px;
      width: 600px;

      @include screen-sm-max {
        max-width: 100%;
        width: 100%;
      }
    }

    .modal-content {
      overflow: hidden;
      position: relative;
      max-width: 100%;
      width: 600px;

      @include screen-sm-max {
        max-width: 100%;
        width: 100%;
      }
    }

    .modal-title {
      display: none !important;
    }
  }

  &.not-closable {
    .custom-modal__close-button {
      display: none;
    }

    .modal-title:after {
      content: none;
    }
  }
}

.modal-backdrop {
  display: none;
}

.modal {
  background-color: $darker-gray;

  @include screen-xl-min {
    background: rgba(0, 0, 0, 0.3);
  }
}

.modal :not([data-modal-active='active']) .modal-content {
  opacity: 0;
}

.custom-modal-with-image {
  .modal-header {
    padding-top: 0 !important;
  }

  .modal-body {
    position: initial;
  }

  .modal-dialog {
    border-top-right-radius: 23px;
    margin: 0 auto;

    @include screen-xl-min {
      border-top-right-radius: 3px;
    }
  }

  &__content,
  .custom-modal__title {
    max-width: 70%;
  }

  .custom-modal__close-button {
    &:before {
      font-size: 20px;
      z-index: 3;
      color: $white;
      position: absolute;
      right: 16px;
      top: 18px;
    }

    &:after {
      z-index: 2;
      content: '';
      background-color: #3f3f3f;
      height: 55px;
      width: 55px;
      position: absolute;
      right: 0;
      top: -1px;
      border-top-right-radius: 19px;

      @include screen-xl-min {
        border-top-right-radius: 3px;
      }
    }
  }

  &__image {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
    width: 30%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 19px;
    border-bottom-right-radius: 3px;

    @include screen-xl-min {
      border-top-right-radius: 3px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:115";