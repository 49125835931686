.toggle-switch {
  border: none;
  width: 60px;
  height: 30px;
  position: relative;
  padding-left: 20px;
  background-color: white;
  cursor: pointer;

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 35px;
    height: 30px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: #e6e7e8;
    transition: background-color 100ms linear;
  }

  &:before {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    width: 35px;
    height: 30px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: #e6e7e8;
    transition: background-color 100ms linear;
  }

  &__state {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: #656565;
    transition: left 100ms linear, background-color 100ms linear;
  }

  &.is-active {
    .toggle-switch__state {
      left: 32px;
      background-color: $white;
    }

    &::after,
    &::before {
      background-color: $primary;
    }
  }

  &__text {
    padding-left: 10px;
    white-space: nowrap;
    color: $darker-gray;
    cursor: pointer;
  }
}

;@import "sass-embedded-legacy-load-done:144";