@mixin border-top() {
  border-top: 1px solid #e3e5e7;

  @include screen-xl-min {
    border-top: 1px solid #667079;
  }
}

.summary-box {
  &__main {
    background-color: $darker-gray;
  }

  &__prices {
    @include border-top;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    position: relative;
    z-index: 1;
    background-color: #4e565e;
  }

  &__expiration-time {
    background-color: $white;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    border: 1px solid $danger;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-top: -5px;
    padding-top: 10px;
    position: relative;
    z-index: 1;
  }

  &--mobile {
    width: 100%;
  }

  &__reservation-item {
    &__icon {
      color: $primary;
      align-self: flex-start;
    }

    &__title {
      font-weight: 500;
      color: $text-muted;
      line-height: initial;
      font-size: $font-sm;
    }

    &__icon,
    &__title {
      @include screen-xl-min {
        color: $light-muted;
      }
    }

    &__value {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-weight: 500;
      white-space: nowrap;
    }
  }

  &__improvements {
    @include border-top;

    .summary-box-actions {
      display: none;

      @include screen-xl-min {
        display: block;
      }
    }

    &__title {
      color: $primary;
      font-size: 13px;

      @include screen-xl-min {
        color: $light-muted;
      }
    }

    &__remove-icon {
      cursor: pointer;
      display: inline-flex;
      align-self: flex-start;
      margin-top: 1px;
      margin-inline: 5px;
      all: unset;

      @include screen-lg-max {
        font-size: 16px;
      }
    }
  }

  &__codes {
    @include border-top;
    font-weight: 500;
    color: $light-muted;

    &__icon {
      color: $primary;
    }

    &__text {
      color: $text-muted;
    }

    &__price {
      color: $white;

      @include screen-lg-max {
        color: $darker-gray;
      }
    }

    &__icon,
    &__text {
      line-height: 1;
      padding: 2px 0;
      display: block;

      @include screen-xl-min {
        color: $light-muted;
      }
    }

    &__edit {
      .custom-form-group {
        margin-top: 0 !important;
      }

      .form-control-with-status {
        &__failed {
          display: none;
        }

        &__spinner {
          top: 7px;
          right: 55px;
        }

        &--active .form-control-with-status__spinner {
          right: 80px;
        }
      }

      .form-error-text,
      .form-error-icon {
        color: white;

        @include screen-lg-max {
          color: $danger;
        }
      }

      .form-error {
        font-size: $font-sm;
      }

      &__action {
        background-color: #576067;
        font-size: $font-xxl;
        padding: 0 8px;
        position: relative;
        all: unset;

        @include screen-lg-max {
          background-color: $white;
        }

        &:after {
          content: '';
          left: 0;
          position: absolute;
          background-color: #979797;
          height: calc(100% + 5px);
          width: 1px;
        }
      }

      &__actions {
        position: absolute;
        padding-right: 5px;
        right: 0;
        top: 0;
        background-color: $darker-gray;
        height: 29px;
        border: 2px solid white;
        border-left: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        overflow: hidden;

        @include screen-lg-max {
          background-color: white;
          border-color: $darker-gray;
        }

        @include screen-xs-max {
          height: 30px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .form-control-with-status {
        color: $white !important;

        @include screen-lg-max {
          color: $darker-gray !important;
        }
      }

      &__input {
        height: 30px;
        padding-right: 75px;
        font-size: $font-sm !important;
        border-radius: 4px;
        max-height: 29px;
        border: 2px solid $white !important;
        background-color: #576067 !important;

        @include screen-lg-max {
          border-color: #576067 !important;
          background-color: $white !important;
        }
      }
    }

    &__added {
      &__description {
        font-size: $font-xs;
        display: block;
        font-weight: 500;
        margin-left: 19px;
        color: $white;
      }

      &__icon {
        font-size: 12px;
        all: unset;

        @include screen-lg-max {
          font-size: 16px;
        }
      }

      &__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 220px;

        @include screen-lg-max {
          max-width: 85vw;
        }
      }

      &__icon,
      &__description,
      &__text {
        @include screen-lg-max {
          color: $darker-gray;
        }
      }
    }
  }

  &__warranty {
    @include border-top();
  }
}

.summary-box-actions {
  cursor: pointer;
  position: relative;
  height: 16px;
  font-style: normal;
  z-index: 2;
  transition: color 100ms linear;
  all: unset;

  &:after {
    content: '';
    position: absolute;
    left: -5px;
    top: -2px;
    width: calc(100% + 10px);
    height: calc(100% + 2px);
    background-color: white;
    z-index: -1;
    opacity: 0;
    transition: opacity 100ms linear;
  }

  &__content {
    position: absolute;
    right: -5px;
    top: 16px;
    width: max-content;
    background-color: white;
    z-index: 1;
    border-radius: 4px 0 4px 4px;
    opacity: 0;
    pointer-events: none;
    max-height: 0;
    overflow: hidden;
    transition: opacity 100ms linear, max-height 100ms linear;
  }

  &--visible {
    color: $darker-gray;

    &:after {
      opacity: 1;
    }

    .summary-box-actions__content {
      opacity: 1;
      max-height: 500px;
      pointer-events: visible;
      box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(66, 68, 90, 0.28);
    }
  }

  &__item {
    align-items: center;
    font-size: 0.875rem;
    color: $darker-gray;

    &:first-of-type {
      @include screen-xl-min {
        border-top: 0 !important;
      }
    }

    &:hover {
      background-color: $light-blue;
    }
  }
}

.summary-box-gallery {
  height: 100px;
  background-color: lightgray;

  .image-gallery-image {
    height: 100px !important;
  }

  .image-gallery-slides {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
}

.summary-box-gallery--mobile {
  .image-gallery-slides {
    border-radius: 3px;
  }

  .image-gallery-image {
    height: 250px;
    min-height: 250px;
    max-height: 250px !important;
  }
}

;@import "sass-embedded-legacy-load-done:119";