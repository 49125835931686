.login-banner {
  opacity: 0;
  transform: translateY(100vh);
  animation: slideIn 1s 1s 1 forwards ease-in;

  &__close {
    all: unset;
    position: absolute;
    right: 0;
    top: calc(0 - (1.5rem + 20px));
    width: 45px;
    height: 45px;
    background-color: #3f3f3f;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 100ms linear, top 200ms linear;

    @include screen-lg-max {
      right: 10px;
    }

    &:hover {
      background-color: rgba(63, 63, 63, 0.95);
    }
  }

  &.is-hidden {
    animation: slideOut 1s 0s 1 forwards ease-in;

    .login-banner__close {
      top: -1.5rem;
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 100%;
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
    opacity: 100%;
  }

  60% {
    opacity: 0;
  }

  100% {
    transform: translateY(100vh);
    opacity: 0;
  }
}

;@import "sass-embedded-legacy-load-done:134";