.opinions {
  &__table {
    tr {
      background-color: $light-blue !important;

      &:nth-child(odd) {
        background-color: white !important;
      }
    }

    td {
      box-shadow: none;
      background-color: inherit;
    }
  }

  &__modal {
    .nav-link {
      border: none;
      color: initial;

      &:focus {
        outline: none;
      }
    }

    .nav-link.active {
      border: none;
      position: relative;
      color: initial;

      &:after {
        content: '';
        position: absolute;
        left: -10px;
        bottom: -10px;
        height: 2px;
        width: calc(100% + 20px);
        background-color: $gold;
        user-select: none;
      }
    }

    .nav-tabs {
      padding-bottom: 9px;
      border-bottom: 2px solid #80808014;
    }

    &__show-more-button {
      width: 100%;
      line-height: initial;

      @include screen-xl-min {
        width: fit-content;
      }
    }
  }

  &__providers-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  &__provider-title {
    white-space: nowrap;
    font-size: $font-sm;

    @include screen-sm-min {
      font-size: $font-md;
    }
  }
}

;@import "sass-embedded-legacy-load-done:117";