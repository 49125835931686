.payment-schedule {
  &__bar {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 12px 10px;
    position: relative;

    @include screen-sm-min {
      justify-content: space-between;
      flex-direction: row;
    }

    &__percentage {
      position: absolute;
      top: -20px;
      font-weight: bold;
      color: $primary;
    }

    &.is-filled.is-rest-value {
      border: 1px solid #d9c097;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    &.is-filled.is-base-value {
      border: 1px solid #bacddf;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    &.is-empty.is-rest-value {
      display: none;
    }

    &.is-empty.is-base-value {
      display: none;
    }

    &.is-base-value {
      width: 30%;
      border: 1px solid #bacddf;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      background: repeating-linear-gradient(
        -45deg,
        rgba(186, 205, 223, 0.1),
        rgba(186, 205, 223, 0.1) 10px,
        rgba(186, 205, 223, 0.2) 10px,
        rgba(186, 205, 223, 0.2) 20px
      );

      .payment-schedule__bar__percentage {
        right: 10px;
        color: $secondary;
      }

      .payment-schedule__price {
        color: $secondary;
      }
    }

    &.is-rest-value {
      border: 1px solid #d9c097;
      border-left: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      width: 70%;
      background: repeating-linear-gradient(
        -45deg,
        rgba(217, 192, 151, 0.1),
        rgba(217, 192, 151, 0.1) 10px,
        rgba(217, 192, 151, 0.2) 10px,
        rgba(217, 192, 151, 0.2) 20px
      );
    }

    &.is-paid {
      opacity: 0.3;
      border: 1px solid rgba(46, 108, 0, 0.2);
      border-radius: 0.25rem 0 0 0.25rem;
      color: $success;

      background: repeating-linear-gradient(
        -45deg,
        rgba(12, 153, 35, 0.02),
        rgba(12, 153, 35, 0.02) 10px,
        rgba(12, 153, 35, 0.05) 10px,
        rgba(12, 153, 35, 0.05) 20px
      );

      .payment-schedule__bar__percentage,
      .payment-schedule__price {
        color: $success;
      }
    }

    &.is-paid + &.is-base-value {
      border-radius: 0;
    }

    &.is-to-pay {
      background: repeating-linear-gradient(
        -45deg,
        rgba(255, 0, 0, 0.02),
        rgba(255, 0, 0, 0.02) 10px,
        rgba(255, 0, 0, 0.05) 10px,
        rgba(255, 0, 0, 0.05) 20px
      );

      .payment-schedule__price {
        color: $danger;
      }
    }

    .payment-schedule__price {
      color: $primary;
    }
  }

  &__wrapper {
    margin-top: 30px;

    @include screen-xs-max {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .payment-schedule__bar__percentage {
        left: 0;
        right: auto;
      }

      .payment-schedule__bar {
        border-radius: 4px;
        border: none;
        width: 100% !important;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:107";