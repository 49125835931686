$optimum: #b585b9;
$comfort: #e47e84;
$lux: #c99d66;
$subscription-with-benefit-color: #ae105f;

@mixin variant($color) {
  .subscription-with-benefits-owner-modal {
    &__label {
      padding: 10px 10px 5px 10px;
      background-color: $color;
    }

    &__table {
      border-color: $color;

      &__price {
        color: $color;
        border-color: $color;
      }

      &__point {
        background-color: $color;
      }
    }
  }
}

.subscription-with-benefits-owner-modal {
  .modal-content {
    max-width: 850px;
    margin: 0 auto;
  }

  &__label {
    border-radius: 0 29px 29px 29px;
    color: white;
    text-align: center;
  }

  &__collapsed-content {
    @include screen-md-min {
      padding-left: 184px;

      &.is-only-check-offset {
        padding-left: 60px;
      }
    }
  }

  &__table {
    border-left: 1px solid gray;
    border-bottom: 1px solid gray;
    border-right: 1px solid gray;
    margin-top: 10px;

    &__price {
      border: 1px solid gray;
      border-radius: 30px;
      text-align: center;
      font-weight: 500;
      padding-block: 4px;
      line-height: 1;

      &.is-regular-client {
        border-color: $danger !important;
        color: $danger !important;
      }
    }

    &__point {
      color: white;
      font-size: $font-xxs;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      min-width: 15px;
      min-height: 15px;
      margin-right: 6px;
      margin-bottom: 5px;
    }
  }

  .is-optimum {
    @include variant($optimum);
  }

  .is-comfort {
    @include variant($comfort);
  }

  .is-lux {
    @include variant($lux);
  }
}

.subscription-with-benefits-owner {
  &__code-label {
    border-radius: 5px;
    padding: 3px 13px;
    background-color: $purple;
  }
}

.subscription-with-benefits {
  $banner-background: linear-gradient(0deg, rgba(255, 197, 226, 0.25) 100%, rgba(174, 16, 95, 0.25) 0);
  @include aside-banner($banner-background, $subscription-with-benefit-color);

  &__banner {
    &__watermark {
      z-index: 0;
      opacity: 0.05;
      height: 200px;
      width: 200px;
      bottom: -80px;
      right: -90px;
      pointer-events: none;
    }
  }

  &__code-input__wrapper {
    .uil-tag-alt {
      position: absolute;
      left: 10px;
      top: 1px;
      font-size: 15px;
      z-index: 3;
    }

    .form-control-with-status__container {
      input {
        padding-left: 30px;
      }
    }
  }
}

.local-for-client-with-benefit {
  &__banner {
    font-size: $font-sm;
    margin-top: 10px;
    width: 100%;
    margin-left: -20px;
    position: fixed;
    color: white;
    background: $subscription-with-benefit-color;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: padding-top 100ms linear, padding-bottom 100ms linear 100ms;

    .product-name {
      position: relative;
      white-space: nowrap;

      &:after {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 40%;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
        margin-top: -5px;
        transition: height 200ms linear;
      }
    }

    &:hover {
      padding-top: 8px;
      padding-bottom: 8px;

      .product-name {
        &:after {
          height: 100%;
          margin-top: -8px;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:135";