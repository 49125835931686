.footer {
  &__wrapper {
    background-color: $light-azure;

    @include screen-xl-min {
      background-color: #f6f8fa;
      background-image: url('/src/assets/images/footer-logo-shape.svg');
      background-position: 30px -300px;
    }
  }

  &__move-top-icon {
    display: flex;
    align-content: center;
    justify-content: center;
    all: unset;

    @include screen-xl-min {
      order: 2;
    }
  }
}

.footer-media {
  @include screen-xl-min {
    order: 3;
  }

  &__link {
    @include screen-xl-min {
      height: fit-content;

      &__icon {
        height: 27px;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:96";