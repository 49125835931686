.spin-input {
  outline: none;
  box-shadow: none;
  border: none;
  background: white;
  font-weight: bold;
  height: 38px;
  color: $darker-gray;
  font-size: 1.125rem;
  max-width: -webkit-fill-available;
  max-width: 25px;
  text-align: center;
  margin: 0 20px;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &__container {
    position: relative;
    background: white;
    border: 1px solid #979797;
    border-radius: 4px;
    min-width: 70px;
    max-width: 100px;
    overflow: hidden;

    &.is-loading {
      .spin-input {
        &__loader {
          right: 5px;
        }

        &__minus {
          left: -20px;
        }

        &__plus {
          right: -20px;
        }
      }
    }
  }

  &__loader {
    transition: right 200ms ease-in-out;
    top: 17px;
    position: absolute;
    right: -30px;
  }

  &__minus,
  &__plus {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $darker-gray;
    cursor: pointer;
    user-select: none;
    font-size: $font-xxl;
    padding-top: 3px;
    all: unset;

    &:before {
      font-weight: 500;
    }

    &.is-disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &__minus {
    left: 5px;
    transition: left 200ms ease-in-out;
  }

  &__plus {
    right: 5px;
    transition: right 200ms ease-in-out;
  }
}

;@import "sass-embedded-legacy-load-done:142";