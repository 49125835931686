.gastro-voucher {
  &__amount-to-spent {
    @include screen-sm-min {
      position: absolute;
      right: 60px;
      top: 57px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:109";