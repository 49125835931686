.mobile-menu {
  &__logo {
    height: 33px;
  }

  &__content {
    width: 90%;
    height: 100%;
    position: fixed;
    background-color: white;
    top: 0;
    left: 100%;
    z-index: 10;
    border: 1px solid white;
    border-radius: 23px 0 0 23px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 15px;
    transition: left 400ms ease-out;
    overflow-y: auto;
  }

  &__overlay {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    background-color: $darker-gray;
    transition: opacity 200ms linear, width 300ms linear 300ms;
    width: 0;
  }

  &__footer {
    width: 100%;
    border-bottom-left-radius: 23px;
    background-color: $darker-gray;

    &__icon {
      font-size: 30px;
    }
  }

  &.is-visible {
    .mobile-menu {
      &__overlay {
        opacity: 100%;
        width: 100%;
        transition: opacity 300ms linear, width 1ms linear;
        transform-origin: left;
      }

      &__content {
        left: 10%;
      }
    }
  }

  &__calculation-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-blue;
    border: 1px solid #9099a1;
    margin-top: 10px;
    color: $text-muted;
    width: 100%;

    @include screen-xl-min {
      width: auto;
      margin-top: 0;
      border: none;
      font-size: 0.875rem;

      &:hover {
        color: inherit;
      }
    }
  }

  &__close {
    margin-top: -3px;
    font-size: 35px;
    color: $text-muted;
    line-height: initial;
    align-self: center;
    all: unset;
  }
}

;@import "sass-embedded-legacy-load-done:118";