@mixin aside-banner($banner-background, $color) {
  &__banner {
    max-width: 280px;
    padding-top: 45px;
    padding-bottom: 10px;
    overflow: hidden;
    display: none;
    border-color: rgba($color, 0.25);
    background: $banner-background;

    @include screen-xl-min {
      display: block;
    }

    &__title {
      position: absolute;
      left: 0;
      top: 0;
      color: white;
      padding-left: 20px;
      padding-right: 30px;
      font-size: 1.3rem;
      min-height: 37px;
      padding-block: 4px;
      border-bottom-right-radius: 30px;
      display: flex;
      align-items: center;
      background: $color;

      &:before {
        content: '';
        display: block;
        height: 0;
        width: 0;
        border: 30px solid transparent;
        position: absolute;
        top: 0;
        right: -19px;
        transition: border-bottom-color 0.3s ease;
        transform: rotate(180deg);
        border-bottom-color: $color;
      }
    }

    &__watermark {
      bottom: -20px;
      height: 140px;
      position: absolute;
      right: -30px;
      width: 150px;
      z-index: 0;
    }
  }
}

.subscription-contract {
  $banner-background: linear-gradient(100deg, rgba(133, 200, 115, 0.25) 0, rgba(64, 149, 45, 0.25) 100%);
  $color: $success;
  @include aside-banner($banner-background, $color);

  &__active-label {
    background-color: $success;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-top: 5px;
    margin-top: -5px;
    color: white;
    position: relative;
    z-index: 2;
  }
}

;@import "sass-embedded-legacy-load-done:132";