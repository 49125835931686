.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 5;
  cursor: default;
  opacity: 0;
  transition: opacity 200ms linear;
  pointer-events: none;

  &--visible {
    opacity: 0.4;
    pointer-events: visible;
  }
}

;@import "sass-embedded-legacy-load-done:121";