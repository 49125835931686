.safety-banner {
  color: #757e85;
  background-color: #e1e9ef;
  border: 1px solid #cdd6dc;
  position: relative;
  overflow: hidden;

  @include screen-xl-min {
    border-radius: 4px;
  }

  &__watermark {
    position: absolute;
    top: 20px;
    left: 75px;
  }

  &__shield {
    min-width: 27px;
  }

  &.is-right-aligned {
    .safety-banner__watermark {
      top: 0;
      right: 0;
      left: auto;
    }
  }
}

;@import "sass-embedded-legacy-load-done:112";