.ecommerce {
  &__banner {
    background: linear-gradient(148deg, #133e9f 0%, #0f2453 100%);
    padding: 14px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    border-radius: 4px;
    text-decoration: none;
    color: white;
    position: relative;
    z-index: 1;

    &:hover {
      color: white;
    }

    &__icon {
      font-size: 45px;
      animation: iconAnimation 3s infinite;
    }

    &__icon-left {
      position: absolute;
      left: 0;
      font-size: 12px;
      top: 33px;
      opacity: 0.5;
      rotate: -9deg;
    }

    &__icon-right {
      position: absolute;
      right: -3px;
      font-size: 18px;
      top: 20px;
      opacity: 0.5;
      rotate: 15deg;
    }

    &__image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      padding: 0;
      border-radius: 4px;
      z-index: 0;
    }

    &__text-hovered {
      height: 0;
      opacity: 0;
      transition: opacity 200ms ease-in-out, left 200ms ease-in-out;
      overflow: hidden;
      position: relative;
      left: -10px;

      &.is-visible {
        height: auto;
        opacity: 1;
        left: 0;
      }
    }

    &__text-initial {
      opacity: 0;
      height: 0;
      transition: opacity 200ms ease-in-out, left 200ms ease-in-out;
      overflow: hidden;
      position: relative;
      left: -10px;

      &.is-visible {
        height: auto;
        opacity: 1;
        left: 0;
      }
    }

    &__icon-initial {
      overflow: hidden;
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 1;
      height: 0;
      opacity: 0;
      left: -5px;
      transition: opacity 200ms ease-in-out, left 200ms ease-in-out;

      &.is-visible {
        height: auto;
        opacity: 1;
        left: 0;
      }
    }

    &__icon-hovered {
      overflow: hidden;
      height: 0;
      padding-right: 0;
      justify-content: center;
      position: relative;
      z-index: 1;
      opacity: 0;
      left: -5px;
      transition: opacity 200ms ease-in-out, left 200ms ease-in-out;

      &.is-visible {
        height: auto;
        opacity: 1;
        left: 0;
      }
    }
  }
}

@keyframes iconAnimation {
  0% {
    opacity: 100%;
    rotate: -9deg;
  }
  50% {
    opacity: 30%;
    rotate: -35deg;
  }
  100% {
    opacity: 100%;
    rotate: -9deg;
  }
}

;@import "sass-embedded-legacy-load-done:136";