@mixin collapsable-theme($color, $background-color) {
  .accordion-button {
    color: $color;
    background-color: $background-color;

    &:focus {
      background-color: $background-color;
    }
  }

  .accordion-body {
    background-color: $background-color;
    color: $text-muted;
    padding: 0;
  }

  .accordion-item {
    background-color: $background-color;
  }
}

.collapsable-item {
  border-top: 1px solid #9da5ac;
  border-bottom: 1px solid #9da5ac;

  & + & {
    border-top: none;
  }

  &__title {
    max-width: 85%;
  }

  .accordion-item {
    border: none;
  }

  .accordion-button {
    font-size: 1.125rem;
    padding-left: 0;
    box-shadow: none;
    padding-top: 18px;
    padding-bottom: 18px;
    align-items: center;
    height: 65px;
    font-weight: $font-weight-bold;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#6c757d'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
      display: flex;
      align-items: center;
    }
  }

  &__secondary {
    @include collapsable-theme($secondary, $light-azure);
  }

  &__primary {
    @include collapsable-theme($darker-gray, $white);
  }
}

;@import "sass-embedded-legacy-load-done:113";