.step {
  &__number {
    position: relative;
    z-index: 2;
    font-size: 14px;
    color: rgba($text-muted, 0.5);
  }

  &__name {
    line-height: initial;
    color: rgba($text-muted, 0.5);
    margin-left: 10px;
  }

  &__circle-wrapper {
    position: relative;

    .step__number {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__completed-icon {
    display: none;
  }

  &__container {
    display: flex;
    align-items: center;

    &.is-active {
      .step {
        &__name {
          color: $secondary;
        }

        &__number {
          color: $gold;
        }
      }
    }

    &.is-completed {
      .step {
        &__number {
          display: none;
        }

        &__wrapper {
          margin-right: 5px;
          width: fit-content;

          &:before,
          &:after {
            content: none;
          }
        }

        &__completed-icon {
          display: inline-block;
          font-size: 17px;
        }
      }
    }
  }

  &__bottom-card {
    z-index: 1;
    display: none;
    flex-direction: row;
    justify-content: space-between;

    @include screen-xl-min {
      display: flex;
    }
  }
}

.step-button {
  display: flex;
  justify-content: center;
  padding: 11px 10px 11px 24px;
  align-items: center;
  background: $gold-gradient;
  border: none;
  color: white;
  transition: background-color 200ms linear;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    opacity: 0.9;
    color: white;
  }

  &:disabled {
    color: #e1e4e5;
    border: 2px solid #e1e4e5;
    background: white;
  }

  &.is-prev {
    align-self: flex-start;
    flex-direction: row-reverse;
    background: transparent;
    color: #9099a1;

    &:active {
      box-shadow: none;
    }

    &:hover {
      color: #9099a1 !important;
    }
  }
}

;@import "sass-embedded-legacy-load-done:120";