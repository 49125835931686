@mixin fadeTransitions($maxHeight: 580px) {
  &-enter {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
  }

  &-enter-active {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }

  &-enter-done {
    opacity: 1;
    max-height: $maxHeight;
    transition: opacity 100ms linear, max-height 200ms linear;
  }

  &-exit {
    opacity: 1;
    max-height: $maxHeight;
  }

  &-exit-active {
    opacity: 0;
    max-height: 0;
    transition: opacity 100ms linear, max-height 200ms linear;
  }
}

.fade {
  @include fadeTransitions();
}

;@import "sass-embedded-legacy-load-done:5";