.header {
  &__logo {
    height: 33px;

    @include screen-xl-min {
      height: 45px;
    }
  }
}

.hamburger-menu {
  margin-left: auto;

  @include screen-xl-min {
    display: none;
  }

  &__label {
    display: flex;
    flex-direction: column;
    width: 35px;
    cursor: pointer;
  }

  &__label .hamburger-menu__line {
    background: $light-gray;
    border-radius: 10px;
    height: 3px;
    margin: 3px 0;
    transition: 400ms cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  &__line {
    margin-left: auto;
  }

  &__line:nth-of-type(1) {
    width: 50%;
  }

  &__line:nth-of-type(2) {
    width: 100%;
  }

  &__line:nth-of-type(3) {
    width: 75%;
  }

  &__input {
    display: none;
  }

  &__input:checked ~ .hamburger-menu__line:nth-of-type(1) {
    transform-origin: bottom;
    transform: rotatez(45deg) translate(7px, 12px);
    opacity: 0;
  }

  &__input:checked ~ .hamburger-menu__line:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg);
  }

  &__input:checked ~ .hamburger-menu__line:nth-of-type(3) {
    transform-origin: bottom;
    width: 100%;
    transform: translate(0, -10px) rotatez(45deg);
  }
}

.shiny-dot {
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: -3px;
    height: 6px;
    width: 6px;
    background-color: #0acf97;
    border-radius: 50%;
    box-shadow: 0 0 3px 0.5px #828282;
  }

  &--red {
    &:after {
      background-color: #dd3535;
      box-shadow: 0 0 3px 0.5px #d96767;
    }
  }

  &--top-corner {
    &:after {
      right: auto;
      left: 3px;
      top: 30%;
    }
  }
}

;@import "sass-embedded-legacy-load-done:95";