.black-week {
  &__banner {
    color: white;
    position: sticky;
    top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../images/black-week-banner-bg.webp');
    z-index: 100;

    &.is-christmas-discount {
      background-image: url('../images/christmas-discount-banner-bg.webp');
    }

    &.is-february-discount {
      background-image: url('../images/february-discount-banner-bg.webp');
    }
  }

  &__modal-image {
    width: 100%;
    height: 600px;

    &__close-button {
      color: $text-muted;
      position: absolute;
      top: 35px;
      right: 5px;
      background: white;
      border-radius: 50%;
      border: gray;
      min-width: 40px;
      min-height: 40px;
      height: 40px;
      width: 40px;
      font-size: $font-xxl;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 200ms linear, transform 200ms linear;

      &:active {
        opacity: 0.9;
        transform: scale(0.9);
      }
    }

    @include screen-md-min {
      font-size: 12.5px;
      width: 600px !important;
    }

    &__slide-show {
      white-space: nowrap;
      position: absolute;
      font-weight: bold;
      margin-inline: 10px;
      display: inline-block;
      animation: slides 8s linear infinite;
      transform: translateX(700px);
      top: 2px;
    }
  }
}

@keyframes slides {
  0% {
    transform: translate(700px, 3px);
  }

  100% {
    transform: translate(-200%, 3px);
  }
}

;@import "sass-embedded-legacy-load-done:138";