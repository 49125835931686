.crash-view {
  &__wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
    background-color: white;
  }

  &__content {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include screen-xl-min {
      width: 40%;
    }
  }

  &__image {
    margin: 0 auto;
  }

  &__button,
  &__text {
    max-width: 500px;
  }

  &__logo {
    height: 70px;
    margin-bottom: 100px;
  }
}

;@import "sass-embedded-legacy-load-done:123";