.form-control-with-status {
  text-overflow: ellipsis;
  transition: padding-right 100ms linear, background-color 100ms linear, border 100ms linear;
  color: $darker-gray !important;
  font-weight: 500;
  font-size: 1rem;
  border: thin solid #9099a1;
  width: calc(100% - 2px);
  margin: 0 auto;

  &.is-empty {
    background-color: $light-blue;
  }

  &.is-failed {
    border: 1px solid $danger;
    background-color: #ff00000a !important;
  }

  &__container {
    position: relative;
    overflow: hidden;
  }

  &__spinner,
  &__succeed {
    position: absolute;
    margin: auto;
    right: -25px;
    transition: right 100ms linear;
  }

  &__spinner {
    width: 1.2rem;
    height: 1.2rem;
    border: 0.2em solid;
    border-right: 0.2em solid transparent;
    top: 17px;
  }

  &__succeed {
    top: 11px;
  }

  &--active,
  &--succeed {
    .form-control-with-status {
      padding-right: 40px;
    }
  }

  &--active {
    .form-control-with-status__spinner {
      right: 10px;
    }
  }

  &--succeed {
    .form-control-with-status__succeed {
      right: 10px;
    }
  }

  &--failed {
    .form-control-with-status__failed {
      right: 10px;
    }
  }
}

.form-group-with-confirmation {
  &__button {
    outline: none;
    border: 0;
    cursor: pointer;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 47px;
  }
}

;@import "sass-embedded-legacy-load-done:124";