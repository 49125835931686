.modal-steps {
  position: absolute;
  bottom: 0;
  right: 0;

  &__wrapper {
    display: flex;
    align-items: center;
    background: $white;
    height: 60px;
    padding: 0 24px;
    border-top-left-radius: 24px;
    position: relative;

    &::before {
      background: $white;
      position: absolute;
      content: '';
      display: block;
      width: 22px;
      height: 55px;
      left: -13px;
      bottom: -10px;
      transform: rotate(30deg);
    }
  }

  &__number {
    color: $darker-gray;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

;@import "sass-embedded-legacy-load-done:130";