.operation-result {
  &__title {
    color: $primary;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }

  &__content,
  &__title {
    width: 400px;
    max-width: 100%;
  }

  &__mobile-banner {
    position: relative;
    background-color: #f0f4f8;
    border-radius: 4px;
    max-width: 980px;
    width: 980px;
    padding: 50px 100px;
    margin-top: 125px;
    height: 351px;
    display: flex;

    @include screen-lg-max {
      display: none;
    }

    &__image {
      position: absolute;
      top: -100px;
      right: -50px;
    }
  }
}

.payment-failure {
  &__phone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $darker-gray;
    margin-top: 10px;
    text-decoration: none;

    &:hover {
      color: $darker-gray;
    }

    &__call-center-indicator {
      &:after {
        content: '';
        position: absolute;
        right: 26px;
        top: 6px;
        height: 4px;
        width: 4px;
        background-color: #0acf97;
        border-radius: 50%;
        box-shadow: 0 0 3px 0.5px #828282;
      }

      &--red {
        &:after {
          background-color: #dd3535;
          box-shadow: 0 0 3px 0.5px #d96767;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:131";