.tooltip-inner {
  opacity: 1;
  background-color: #3f3f3f !important;
  padding-block: 7px;
  padding-inline: 12px;
}

.tooltip-arrow:before {
  border-top-color: #3f3f3f !important;
}

.tooltip {
  transition: opacity 200ms ease-in;

  &.show {
    opacity: 0.95;
  }
}

;@import "sass-embedded-legacy-load-done:122";