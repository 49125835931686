.checkbox {
  display: flex;
  align-items: center;

  &.is-cursor-pointer > * {
    cursor: pointer;
  }

  .form-check-input {
    cursor: pointer;
    height: 25px;
    width: 25px;

    &:active {
      filter: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.is-muted-label .form-check-input:checked + .form-check-label {
    color: $text-muted;
  }

  .form-check-label {
    user-select: none;
    line-height: initial;
    height: min-content;
    color: $text-muted;
    padding-top: 3px;
    padding-left: 10px;
    width: 100%;
  }

  &--big {
    .form-check-input {
      min-height: 31px;
      min-width: 31px;
      height: 31px;
      width: 31px;
    }
  }

  &--small {
    .form-check-input {
      min-height: 19px;
      min-width: 19px;
      height: 19px;
      width: 19px;
    }
  }

  &--xs {
    .form-check-input {
      min-height: 16px;
      min-width: 16px;
      height: 16px;
      width: 16px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:141";