.content-loader {
  position: relative;
  max-height: 235px;
  min-height: 235px;
  overflow: initial;
  transition: max-height 2s ease, min-height 500ms ease;
  pointer-events: none;

  &.is-hidden {
    pointer-events: all;
    max-height: 10000px;
  }

  &.is-full-screen {
    min-height: 100vh;
    max-height: 100vh;
  }

  &:not(.is-hidden).is-positioned-to-top {
    min-height: 350px;
  }

  &.is-positioned-to-top {
    max-height: initial;

    & > .content-loader__loader {
      top: 100px;
      transform: translateX(-50%);
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-height: 235px;
    z-index: 2;
    opacity: 1;
    pointer-events: none;
  }

  &.is-hidden:after {
    @include fadeOutAnimation;
  }

  &__loader {
    width: 150px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 3;
    pointer-events: none;

    @include screen-md-max {
      top: 40px;
      transform: translate(-50%, 0);
    }

    &.is-hidden {
      @include fadeOutAnimation;
    }
  }

  &__content {
    opacity: 0;

    &.is-visible {
      animation-delay: 300ms;
      @include fadeInAnimation;
    }
  }
}

;@import "sass-embedded-legacy-load-done:127";