.collapsable-resort {
  &__card {
    display: grid;
    position: relative;
    grid-template-columns: 100%;

    @include screen-sm-min {
      grid-template-columns: 40% 60%;
    }

    &__action {
      margin-left: 5px !important;
    }
  }

  &__action-button {
    padding-left: 40px;

    .trapezoid__button {
      padding-top: 10px;
      padding-bottom: 10px;

      &:before {
        border: 43px solid transparent;
        border-bottom-color: $red;
        left: -24px;

        @include screen-xs-max {
          left: -25px;
        }
      }
    }
  }

  &__expanded-arrow {
    position: absolute;
    right: 18px;
    bottom: 1px;
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-top: 18px solid $danger;
    transition: bottom 150ms linear, opacity 150ms linear;
    opacity: 0;

    &.is-visible {
      bottom: -17px;
      opacity: 1;
    }
  }

  .accordion-button {
    padding: 0;

    &:after {
      content: none;
    }
  }

  .accordion-item {
    border: none;
  }

  &__opinions-gallery {
    display: none;

    @include screen-xs-max {
      display: block;
      bottom: -2px;
      position: absolute;
      right: 15px;
      z-index: 2;
      background-color: $white;
      padding: 6px;
      border-radius: 3px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:101";