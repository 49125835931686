.improvement-modal {
  &__vip-list {
    column-count: 2;
    display: flex;
    flex-direction: column;

    @include screen-xl-min {
      display: block;
    }
  }

  &__amount-change {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include screen-xs-max {
      width: 100%;
      flex-direction: row;
      align-items: center;
    }
  }
}

;@import "sass-embedded-legacy-load-done:105";