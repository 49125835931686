.aside {
  width: 280px;
  min-width: 280px;

  &__floating {
    position: sticky;
    top: 15px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

;@import "sass-embedded-legacy-load-done:97";