.apartment-details {
  &__content {
    width: 100%;

    @include screen-xl-min {
      width: 75%;
    }
  }

  &__improvements {
    &__show-more-button {
      width: 100%;
      font-weight: bold;

      @include screen-xl-min {
        color: $darker-gray;
        width: auto;
        border: none;
        padding: 0;
        font-size: 0.875rem;

        &:hover {
          background-color: inherit;
          color: $darker-gray !important;
          outline: none;
          box-shadow: none;
        }
      }
    }

    &__list {
      &-enter {
        max-height: 0;
        opacity: 0;
      }

      &-enter-active {
        max-height: 800px;
        opacity: 1;
        transition: max-height 0.5s linear, opacity 0.5s linear;
      }

      &-exit {
        max-height: 800px;
        opacity: 1;
      }

      &-exit-active {
        max-height: 0;
        opacity: 0;
        transition: max-height 0.5s linear, opacity 0.5s linear;
      }
    }
  }

  &__floating-price {
    bottom: 54px !important;
  }
}

.apartment-localization {
  &__map {
    height: 450px;
  }

  &__attractions {
    &__select-apartment-button {
      line-height: initial;
      width: 100%;

      @include screen-xl-min {
        width: fit-content;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:100";