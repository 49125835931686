$box-color: rgba(255, 255, 255, 0.2);

.date-recommendation {
  &__wrapper {
    margin-top: 12px;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px;
    border-radius: 4px;
    width: 100%;

    @include screen-lg-min {
      padding-top: 10px !important;
    }

    @include screen-sm-min {
      width: 45%;
      padding-top: 30px;
    }
  }

  &__title {
    position: absolute;
    left: 20px;
    top: -9px;
    padding-inline: 8px;
    color: white;
    background-color: $darker-gray;
    font-size: $font-sm;
  }

  &__button {
    margin-top: 5px;
    padding: 0;
    background: transparent;
    all: unset;
    border-radius: 4px;

    &__date {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-top: 1px solid $box-color;
      border-left: 1px solid $box-color;
      border-right: 1px solid $box-color;
      padding-inline: 10px;
      padding-top: 8px;
      padding-bottom: 3px;
      background: $box-color;
      color: white;
      transition: background-color 150ms linear, color 150ms linear;
      margin-bottom: -1px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__selection {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-bottom: 1px solid $box-color;
      border-left: 1px solid $box-color;
      border-right: 1px solid $box-color;
      color: white;
      padding: 3px 10px;
      font-weight: normal;
      font-size: $font-md;
      transition: background-color 150ms linear, color 150ms linear;
      display: flex;
      justify-content: center;
    }

    &:hover {
      .date-recommendation__button__date {
        background: white;
        color: $darker-gray;
      }

      .date-recommendation__button__selection {
        background: rgba(255, 255, 255, 0.9);
        color: $darker-gray;
      }
    }
  }

  &__scroll-to-top {
    &__wrapper {
      padding-top: 15px;
      position: relative;
      min-height: 45px;
      width: 100%;
      background: transparent;
      border: 0;

      @include screen-sm-min {
        min-height: 30px;
      }

      &:hover {
        cursor: pointer;

        .date-recommendation__scroll-to-top {
          top: 5px;

          &__icon {
            opacity: 1;
            top: 8px;
          }
        }
      }
    }

    display: flex;
    flex-direction: column;
    font-size: $font-md;
    font-weight: 500;
    color: white;
    all: unset;
    margin-inline: auto;
    position: absolute;
    transition: top 150ms linear;
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
    width: 100%;
    text-align: center;

    &__icon {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      text-align: center;
      font-size: $font-xxl;
      opacity: 0;
      transition: opacity 150ms linear, top 150ms linear;
      pointer-events: none;
    }
  }

  &__separator {
    color: rgba(255, 255, 255, 0.7);
    font-size: $font-sm;
    width: 50px;
    display: none;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    position: relative;

    @include screen-sm-min {
      display: flex;
    }

    &:after {
      content: '';
      position: absolute;
      background-color: $darker-gray;
      width: 30px;
      height: 30px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      width: 1px;
      height: 100%;
      background: rgba(255, 255, 255, 0.07);
      z-index: -1;
    }
  }
}

;@import "sass-embedded-legacy-load-done:133";