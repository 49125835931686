.feeding-separated {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__check-button {
    flex-direction: column;
    padding: 35px 15px 20px 15px;
    justify-content: center;

    .check-button__text-wrapper {
      align-items: center;
      text-align: center;
    }

    @include screen-xxs-max {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.feeding-modal {
  &__guests-list-container {
    transition: padding-bottom 200ms linear, border-bottom-width 200ms linear;
    border-bottom: 0 solid #dee2e6;
    display: flex;
    flex-direction: column;

    &.is-visible {
      padding-bottom: 10px;
      border-bottom-width: 1px;
    }
  }

  &__guests-list-fade {
    @include fadeTransitions(130px);
  }

  &__guest-birthday-selection {
    pointer-events: none;
    max-height: 0;
    opacity: 0;
    transition: opacity 200ms linear, max-height 200ms linear;
    overflow: hidden;

    &.is-visible {
      opacity: 1;
      max-height: 600px;
      pointer-events: all;
    }

    &__calendar {
      &__month {
        width: 100%;

        .rdrSelected,
        .rdrStartEdge,
        .rdrInRange,
        .rdrEndEdge {
          background-color: $gold;
          border-color: $gold;
        }

        .rdrDayStartPreview.rdrDayEndPreview,
        .rdrDay.rdrDayHovered.rdrDayActive,
        .rdrDayStartPreview,
        .rdrDayInPreview,
        .rdrDayEndPreview {
          color: $gold !important;
        }

        .rdrDayNumber {
          span:after {
            background: $gold;
          }
        }
      }

      &__wrapper {
        width: 100%;
        box-shadow: 0 0 27px -14px rgba(66, 68, 90, 0.19);
        border-radius: 3px;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:104";