.floating-item {
  bottom: 0;
  background: $dark-gray-gradient;
  position: sticky;
  width: 100%;
  z-index: 6;
  padding-top: 1rem;
  padding-bottom: 1rem;
  all: unset;

  &--light {
    background: #e6e7e7;
  }

  &__next-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    opacity: 0;
    transform: translateY(-50%);
    font-size: 30px;
    transition: opacity 100ms linear;

    &.is-visible {
      opacity: 1;
    }
  }

  &__spinner {
    position: absolute;
    right: 13px;
    top: 19px;
    font-size: 8px;
    opacity: 0;
    transition: opacity 100ms linear;

    &.is-visible {
      opacity: 1;
    }
  }

  &__popping-up {
    padding-block: 7px;
    bottom: -100px;
    transition: bottom 200ms linear;
    position: fixed;

    &.is-visible {
      bottom: 0;
    }
  }
}

.floating-item.is-active {
  background: $green-pearl;
}

.floating-phone-button {
  position: fixed;
  bottom: 25px;
  right: 15px;
  font-size: 35px;
  color: white;
  background: linear-gradient(90deg, rgba(179, 149, 89, 1) 0%, rgba(148, 118, 72, 1) 50%);
  border-radius: 50%;
  height: 60px;
  width: 60px;
  z-index: 5;

  &:after {
    content: '';
    width: calc(100% + 14px);
    height: calc(100% + 14px);
    position: absolute;
    top: -7px;
    left: -7px;
    border-radius: 50%;
    background: linear-gradient(90deg, rgba(179, 149, 89, 0.7) 0%, rgba(148, 118, 72, 0.7) 50%);
  }

  &__phone {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 40px;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

;@import "sass-embedded-legacy-load-done:114";